/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// footer 

.footer-nav-menu {
	li {
		display: inline-block;
		list-style-type: none;
		font-size: 13px;
		padding-left: 13px;
		padding-right: 13px;
		cursor: pointer;
		font-weight: 700;
	}

	@include mq("tablet", "max") {
	    padding: 0;
	    padding-top: 12px;
	}

	@include mq('phone-wide', 'max') {
		padding-top: 0;
	}
}