/*
 * Flex UX Framework
 *
 * Filename: faqs-renderer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	.page-body {

		.mdl-accordion {
  			.mdl-accordion__icon {
    			margin-left: auto;
			}
		}
	}

	.mdl-accordion__button {
    	font-weight: 700;
    	font-size: 15px;
    	margin-left: 5px;
    	margin-top: 5px;
    	cursor: pointer;
        line-height: 17px;
	}

	.mdl-collapse__content {

    	padding: 12px;
    	margin-top: 9px;
    	font-size: 14px;
    	line-height: 21px;
    	// color: #666;
    	font-family: $standard-font-face;

    	p {
            line-height: 20px;
    		font-size: 13px;
		}

		a {
    		color: $brand-color;
		}

	}	
}