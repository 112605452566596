/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                       #00493D;
$brand-color-secondary:             #8DC63F;
$brand-color-btn-primary:           #8DC63F;
$brand-color-btn-primary-hover:     rgba($brand-color-btn-primary,0.8);
$brand-color-btn-secondary:         #ECEDE8;
$brand-color-btn-secondary-hover:   rgba($brand-color-btn-secondary,0.8);// #606367;

$primary-font-color:                #726658;
$h1-font-color:                     $primary-font-color;
$h2-font-color:                     $primary-font-color;


//
// General: Font
// 

$h1-font-size:                      34px;
$h2-font-size:                      21px;
$standard-font-face:                "proxima-nova", sans-serif;
$drawer-font:                       "proxima-nova", sans-serif;


//
// Header
//

$header-background:                 #fff; //$brand-color;
$header-height:                     115px;
$header-top-height:                 35px;
$header-main-height:                75px;

$header-tablet-height:              56px;
$header-phone-height:               56px;
$header-logo-padding:               10px;
$header-minicart-badge-color:       #8DC63F;
$header-minicart-badge-border:      1px solid #8DC63F;
$header-top-link-hover-border:      2px solid #000104;
$header-icons-color:                $brand-color; //#fff;
$header-search-font-color:          #726658;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #fff; //$brand-color;


//
// Footer
//

$footer-background:                 #2c2f30;
