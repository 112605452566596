/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: fixed;
	width: 100%;
	background: #00493D;
	z-index: 2;
	height: $header-height;

	@include mq('tablet', 'max') {
		height: 56px;
	}

	.site-header {
		height: $header-top-height;
		background: #efeff1;
		font-weight: bold;
		font-family: $standard-font-face;
		font-size: 10px;
		color: #000;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__welcome {
			padding: 7px 0;
			padding-left: 158px;
			font-size: 13px;
			text-transform: none;
			padding-left: 40px;

		@include mq('desktop-wide', 'max') {
			padding-left: 100px;	
		}

		@include mq('tablet', 'min') {
			padding-left: 0px;	
		}

		}

		&__nav-menu {
			margin: 0;
			margin-right: 10px;
			padding-left: 15px;

		@include mq('desktop-wide', 'max') {
			padding-left: 5px;	
		}
		
		@include mq('tablet', 'max') {
			display: none;
		}

			li {
				display: inline-block;
				list-style-type: none;
				font-size: 13px;
				padding-left: 13px;
				padding-right: 13px;
				line-height: 35px;
				cursor: pointer;
				font-weight: 700;

				@include mq('desktop-wide', 'max') {
					padding-left: 5px;
					padding-right: 3px;
				}

				a {
					color: #00493D;
					text-decoration: none;
					text-transform: capitalize;
					font-weight: 700;
					font-size: 16px;

				@include mq('desktop-wide', 'max') {
					font-size: 13px;
				}

				}

				&:hover {
					// box-shadow: inset 0px -2px #000104;
					text-decoration: underline;
				}

			}

		}

		.site-header__left {
			position: absolute;
			left: 0;
			top: 0;
			max-height: $header-top-height;

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}

	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		font-weight: 700;
		padding-left: 0px;
		padding-right: 35px;
		height: $header-main-height;
		color: #000;
		font-family: $standard-font-face;
		background: $header-background;

		@include mq('tablet', 'max') {
			height: $header-tablet-height;
			padding-right: 49px;
		}

		@include mq('phone-wide', 'max') {
			height: $header-phone-height;
			padding-right: 49px;
		}

		@include mq('desktop', 'min') {
			padding-right: 0px;
		}

		@include mq('desktop-wide', 'min') {
			padding-right: 35px;
		}

		@include mq('tablet', 'min') {
			padding-right: 5px;
		}

		&__left, &__right {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.main-nav__right {
		flex-flow: row nowrap;

		.mdl-button--search {

			.material-icons {
				font-size: 27px;
				height: 27px;
				width: 27px;
			}

		}

		.mdl-textfield--expandable {
			padding: 0;
		}

		.mdl-textfield {
			padding: 4px 0;

		@include mq('desktop-wide', 'max') {
			width: 200px;	
		}

		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: center;
		}

		.search-textfield-container {
			position: relative;
			border: solid 1px #D9D9D9;
			border-radius: 15px;
			height: 30px;
			width:calc(100% - 20px);

			.mdl-textfield__label::after {
				background-color: $header-search-underline-color !important;
				left: 0;
				visibility: visble;
				width: 100%;
			}

			.mdl-textfield__input {
				color: $header-search-font-color;
				position: relative;
				width: calc(100% - 40px);
				margin-left: 40px;
				border: none;
				padding: 0;
				line-height: 23px;
			}

			.mdl-textfield .mdl-button {
				position: absolute;
				bottom: 1px;
				left: 10px;
			}

			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: $header-icons-color;
			transform: translate(-14px, -11px);
		}

		.cart {
			margin-left: 8px;

			.cart-container {
				width: 34px;
				height: 34px;

				.material-icons::after {
					border: $header-minicart-badge-border;
					width: 12px;
					height: 12px;
					top: -3px;
					right: -1px;
					font-size: 9px;
					background: $header-minicart-badge-color;
					color: #1C3D29;
				}

			}

			.mdl-menu {
				&__container {
					right: -15px;
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: #000;

						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
							}

							.cart-title {
								font-size: 23px;
								text-transform: uppercase;
								line-height: 1.75;

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;

								.cart-item-img {
									display: flex;
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											font-weight: 700;
											font-size: 13px;
											line-height: 16px;
											text-decoration: none;
										}

									}

									.cart-item-quantity {
										flex: 1 50%;
										font-weight: 400;
									}

									.cart-item-price {
										flex: 1 50%;
										color: $brand-color;
									}

								}

							}

						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							}

							.cart-subtotal {
								font-size: 18px;

								@include mq('tablet', 'max') {
									font-size: 15px;
								}

							}

							.checkout-btn:hover {
								background-color: $brand-color-btn-primary-hover;
							}

							.mdl-button--colored {
								font-family: $standard-font-face;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: 1px;
								background: $brand-color;
								color: white;
							}

						}

					}

				}

			}

		}

	}

	.main-nav__left {
		flex-flow: row wrap;
		padding: $header-logo-padding;

		.material-icons {
			line-height: 24px;
			vertical-align: middle;
		}

		.callus {
			@include mq('tablet', 'max') {
				display: none !important;
			}

			@include mq('desktop', 'min') {
				margin-left: 5px;
			}

			@include mq('desktop-wide', 'min') {
				margin-left: 34px;
			}

			@include mq('tablet', 'min') {
				margin-left: 5px;
			}

    		line-height: 24px;
			margin-bottom: 0;
			color: #00493D;
			font-size: 16px;
			margin-left: 34px;
		}

		.classuslink {
    		display: inline-block;
    		margin: 0px;
    		line-height: 24px;
    		font-size: 16px;
    		vertical-align: middle;

    		@include mq('tablet', 'min') {
				font-size: 10px;	
			}

			@include mq('desktop', 'min') {
				font-size: 16px;	
			}

    		a {
    			text-decoration: none;
    			color: #00493D;
    		}
		}

		.desktop-nav {
			@include mq('tablet', 'max') {
				display: none !important;
			}

			display: flex;
			flex-flow: row wrap;
			padding-top: 9px;
			margin: 0px;
			padding: 0px;
			width: 100%;
			// left: calc(49vw - 323px); //without
			// left: calc(46vw - 334px);
			left: calc(49vw - 403px);  //with service menu
			position: relative;
			top: $header-logo-padding;

			ul:first-of-type {
				position: absolute;
			}

			& > ul {
				margin-top: 4px;
				padding-left: 0px;
				flex: 1 1 100%;
				display: flex;
			}

			& > ul > li {
				position: relative;
				display: inline-block;
				list-style: none;
			}

			& > ul > li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;

				.nav-handle {
					// text-align: left;
					position: relative;
					margin-left: 0;

					a span {
						margin-right: 12px;
					}

					a[aria-label="Service Awards"] span {
						margin-right: 27px;
					}
				}

				> .nav-handle {
					::after {
						font-family: 'Material Icons';
						content: '\e5cb';
						display: inline-block;
						transform: rotate(-90deg);
						font-size: 26px;
						color: #ffffff;
						position: absolute;
						right: 16px;
						top: -1px;
						clip: rect(6px, 22px, 32px, 0px);
					}

				}

				.sub-nav {
					z-index: 10;
					padding: 8px 0;
					display: none;
					opacity: 0;

					&:first-of-type{
						// min-width: 196px;
						min-width: 220px;
					}

					.sub-nav-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: nowrap;

						a {
							color: #726658;
							text-align: left;
						}
					}

					.nav-handle {
						padding: 0 24px;
					}

				}

				.sub-nav [data-subnav] {
					& > .nav-handle {
						padding-right: 45px;
						width: calc(100% - 69px);

						::after {
							font-family: 'Material Icons';
							content: '\e5cc';
							display: inline-block;
							font-size: 22px;
							color: $brand-color;
							position: absolute;
							right: 10px;
							top: -3px;
							clip: rect(6px, 22px, 32px, 0px);
						}

					}

					.subSubNavContainer {
						display: none;
						position: absolute;
						top: 0;
						left: 99.5%;
					}

				}

			}

			& > ul > li {
				.nav-handle {
					//	text-align: left;
					border-radius: 0;
					min-width: 115px;
					height: 31px;
					padding: 0 18px;
					display: flex;
					flex-flow: column;
					justify-content: center;

					& > a {
						// line-height: 0;
					}

				}

				a {
					color: #ffffff;
					text-transform: none;
					text-decoration: none;
					font-family: $standard-font-face;
					white-space: nowrap;
				}
				/* The ::after icon spacing */
				.nav-handle:first-of-type{
						// padding-right: 35px;
					}
				.nav-handle-line-1 {
					&-1,
					&-2 {
						padding-right: 35px;
					}

					a {
						font-size: 15px;
						font-weight: 700;
					}
				}

			}

		}

		.logo {
			max-width: none;
			max-height: none;
			height: calc(#{$header-main-height} - (#{$header-logo-padding} * 2));
			// max-height: calc(100% - (#{$header-logo-padding} * 2));
			// padding: $header-logo-padding 0 $header-logo-padding $header-logo-padding;
			// position: relative;
			// top: $header-logo-padding;

			@include mq('tablet', 'max') {
				max-height: calc(#{$header-tablet-height} - #{$header-logo-padding});
				margin-left: $header-logo-padding;
			}

			@include mq('phone-wide', 'max') {
				max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
				margin-left: $header-logo-padding;
			}

		}

	}
}

.mobile-drawer-nav {
	position: fixed;
	z-index: 100;
	pointer-events: none;
	font-family: $standard-font-face;

	.drawer-logo-container {
		background: $drawer-mobile-background;
		margin-top: 0;
		height: 56px;
		padding: 0px;
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		font-weight: bold;
		border: none;
		text-transform: uppercase;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $header-icons-color;
			top: -5px;
			left: auto;
			right: -4px;
			pointer-events: all;
		}

	}

	.mdl-navigation {
		padding-top: 0;
		font-size: 14px;

		ul {
			font-size: 14px;
			padding-left: 20px;
		}

	}

	ul {
		.mobile-nav-handle {
			a {
				color: black;
				font-weight: 700;
			}

		}

		[data-subnav] {
			i.material-icons {
				color: $brand-color;
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;
		}

	}

	ul.top-buttons {
		li.top-btn {
			a {
				color: $brand-color;
				font-weight: 700;
			}

		}

	}

	.back-button {
		cursor: pointer;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;

		i.material-icons {
			color: $brand-color;
			margin-right: 10px;
		}

	}

	.sub-nav-title {
		font-weight: bold;

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 25px;
			color: $brand-color;
			margin-right: 6px;
		}

	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;

		.mdl-textfield {
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
			padding-top: 14px !important;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid lightgray;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty, .mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $brand-color;
		}

	}

	// .search-textfield-container

	.material-icons {
			line-height: 24px;
			vertical-align: middle;
		}

		.callus {
    		line-height: 24px;
			color: #00493D;
			font-size: 16px;
			margin: 20px auto 0;
		}

		.classuslink {
    		display: inline-block;
    		margin: 0px;
    		line-height: 24px;
    		font-size: 16px;
    		vertical-align: middle;

    		a {
    			text-decoration: none;
    			color: #00493D;
    			text-transform: none;
    		}
		}
}

//.mobile-drawer-nav

nav ul li a {
	text-decoration: none;
}

.page-header {
	padding-top: $header-height;
	background: #fff;
	display: block;

	@include mq("tablet", "max") {
		padding-top: 56px;
	}

	@include mq("tablet", "max") {
		flex-flow: column-reverse nowrap;
	}
}

.page-header, .page-header .category-header-img {
	@include mq('phone', 'max') {
		height: 100px;
	}
}

.nav-handle.mdl-button.mdl-js-button.mdl-js-ripple-effect.nav-handle-line-1.nav-handle-line-1-1 {
	min-width: 175px;
}