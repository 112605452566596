/*
 * Flex UX Framework
 *
 * Filename: checkout_payment.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-payment {

	.gift-cert {
        width: 100%;  
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
		    margin-bottom: 20px; 
		button {
		    background-color: #8DC63F;
			font-size: 16px;
			font-family: "proxima-nova",sans-serif;
			color: #1C3D29;
			padding: 0px 15px;
			transition: background 0.15s;
			height: 45px;
			font-weight: 700;
			letter-spacing: 1px;

			&:hover {
				background-color: rgba(141, 198, 63, 0.8);
			}
		}
	}


	.continue-button-plp {
		margin-top: 20px;
	}
}