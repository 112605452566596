/*
 * Flex UX Framework
 *
 * Filename: home.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home {
	.about-us {
    	margin: 12px 12px 32px 12px;

    	@include mq("phone-wide", "max"){
			margin: 6px;
		}
	}

	.featured-links {
		display: flex;
		justify-content: space-between;
		margin: 4px 0;
		box-sizing: border-box;

		.featured-link {
			color: #fff;
			font-size: 30px;
			text-decoration: none;
			text-align: center;
			background-color: #00493D;
			border-radius: 5px;

			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 14px;
			width: 100%;
			max-width: 300px;
			height: 128px;
			box-sizing: border-box;

			&:not(:last-child) {
				margin-right: 25px;
			}
		}

		@media only screen and (max-width: 700px) {
			.featured-link {
				font-size: 24px;
			}
		}

		@media only screen and (max-width: 450px) {
			flex-direction: column;
			margin: 4px 12px;

			.featured-link {
				max-width: none;
				margin-bottom: 10px;
				
				&:not(:last-child) {
					margin-right: 0;
				}
			}
		}
	}

	.featuredboxes {

		margin:16px 12px 12px 12px;

	 	h1 {
	 		color:#00493D;
	 		text-align: center;
	 		margin-bottom: 20px;
	 		position: relative;
    		font-size: 30px;
    		z-index: 1;
    		overflow: hidden;

    		&:before, &:after {
    			position: absolute;
    			top: 51%;
    			overflow: hidden;
    			width: 15%;
    			height: 1px;
    			content: '\a0';
    			background-color: #00493D;
			}

			&:before {
    			margin-left: -16%;
    			text-align: right;
			}

			&:after {
    			margin-left: 1%;
    			text-align: right;
			}
	 	}

	 	&--content {
	 		display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			max-width: 948px;

			@media only screen and (max-width: 978px) {
				justify-content: center;
			}

			@media only screen and (max-width: 670px) {
				justify-content: space-between;
			}

	 		.fbox {
	 			max-width:300px;
	 			min-width: 300px;
	 			height:200px;
	 			display:inline-block;
	 			background-position: center;
	 			background-color: #CDE1D7;
	 		}

	 		a {
	 			color:#ffffff;
	 			text-decoration: none;text-align: center;
	 			// display: block;
	 			display: flex;
	 			justify-content: center;
	 			align-items: center;
	 			width: 300px;
	 			height: 200px;
	 			font-size: 32px;
	 			// line-height: 45px;
	 			line-height: 1.15em;
	 			text-shadow: -1px 1px 5px rgba(0,0,0,0.75);
	 			background-repeat: no-repeat;
	 			background-position: bottom;
	 			width: 100%;

	 			span {
	 				// position: relative;
					// top: 142px;
					margin-top: auto;
					margin-bottom: 18px;
	 			}

	 			@media only screen and (max-width: 400px) {
	 				font-size: 28px;
	 			}

	 		}

	 		.fbox1 {
	 			a {
	 				background: url(../images/cat1-items.png) no-repeat scroll bottom;
	 			}

	 			@media only screen and (max-width: 978px){
	 				max-width: 620px;
					min-width: 620px;
					margin-bottom: 23px;
	 			}

	 			@media only screen and (max-width: 670px) {
	 				min-width: 100%;
	 				margin-bottom: 4%;
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;

	 				a {
	 					height: 175px;
	 					background-size: calc(175px + 15vw);
	 				}		
	 			}
	 		}

	 		.fbox2 {
	 			a {
	 				background: url(../images/cat2-items.png) no-repeat scroll center 50% / 100%;

	 			}

	 			@media only screen and (max-width: 978px) {
	 				margin-right: 11px;
	 			}

	 			@media only screen and (max-width: 670px) {
	 				min-width: 48%;
	 				width: 48%; 
	 				margin-right: 0; 	 				
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;

	 				a {
	 					height: 175px;
	 				}		
	 			}
	 		}

	 		.fbox3 {
	 			
	 			a{
	 				background: url(../images/cat3-items.png) no-repeat scroll center 50% / 70%;
	 			}

	 			@media only screen and (max-width: 978px) {
	 				margin-left: 11px;
	 			}

	 			@media only screen and (max-width: 670px) {
	 				min-width: 48%;
	 				width: 48%;
	 				margin-left: 0;
	 			}

	 			@media only screen and (max-width: 480px) {
	 				max-height: 175px;
	 				height: 175px;

	 				a {
	 					height: 175px;
	 				}		
	 			}

	 			@media only screen and (max-width: 330px) {
	 				width: 48%;
	 			}
	 		}
	 	}
	}

	@media only screen and (max-width: 995px) and (min-width: 725px) {
		.product-list {
			width: 75%;
			margin: auto!important;
		}
	}
}