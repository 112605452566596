/*
 * Flex UX Framework
 *
 * Filename: spotlight_slider.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */ 

.hero-text {
    letter-spacing: 4px;
    color: white;
    font-size: 115px;
    font-weight: 600;
    padding:5%;

    p {
    	font-size: 14px;
		line-height: 1.5em;
    	text-align: center;

        @media only screen and (max-width: 400px) {
            font-size: 12px;
        }
    }

    h1 {
        color:#ffffff;
        text-transform: none;
        text-align: center;
        font-size: 36px;
        position: relative;
        // top: 5px;
        margin-top: 27px!important;

        @include mq('desktop-wide', 'max') {
            font-size: 32px;
            padding-top: 10px;
        }

        @media only screen and (max-width: 1040px) {
            // font-size: calc(30px - 1vw);
            font-size: 30px;
        }

        @media only screen and (max-width: 980px) {
            font-size: calc(30px - .5vw);
        }

        @media only screen and (max-width: 700px) {
            font-size: calc(28px - .5vw);
            // font-size: 28px;
        }

        @media only screen and (max-width: 520px) {
            font-size:  calc(26px - .5vw);
            margin-top: 40px !important;
        }

        @include mq('phone-wide', 'max') {
            font-size:  calc(24px - .5vw);
        }

        @media only screen and (max-width: 445px) {
            font-size: calc(24px - .5vw);
        }

        @media only screen and (max-width: 420px) {
            font-size: calc(22px - .5vw);
        }

        @media only screen and (max-width: 400px) {
            // font-size: calc(22px - .5vw);
            font-size: 5vw;
            padding-bottom: 1.5vw;
        }
    }
}

.sliderlogo {
    max-width: 198px;
	margin: 0 auto;
	width: 60%;
	position: absolute;
	top: -52px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;

    @include mq('tablet-small', 'max') {
        // width: 20vw;
        width: calc(198px - 4vw);
    }

    @include mq('phone-wide', 'max') {
        // width: calc(198px - 10vw);
        width: 55%;
    }
}

.hero-carousel-overlay {
    height: 200px;
    width: 50%;
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    left: 50%;
    margin-left: -25%;
    top: 50%;
    margin-top: -40px;

    @include mq('tablet', 'max') {
        width: 70%;
        left: 15%;
        margin-left: auto;
    }

    @media only screen and (max-width: 600px) {
        width: 80%;
        left: 10%;
    }

    @media only screen and (max-width: 520px) {
        height: 220px;
        top: 45%;
    }

    @media only screen and (max-width: 445px) {
        height: 225px;
        top: 40%;
        width: 85%;
        left: 7.5%;
    }

    @media only screen and (max-width: 400px) {
        height: 200px;
        top: 45%;
    }
}

.hero-carousel {
    width: 100%;
    max-width: 100%;
    height: 450px !important;
	min-height: 450px !important;
	max-height: 450px !important;
	margin-bottom: 0;

    .slick-slider .slick-track, .slick-slider .slick-list {
    	-webkit-transform: translate3d(0, 0, 0);
    	-moz-transform: translate3d(0, 0, 0);
    	-ms-transform: translate3d(0, 0, 0);
    	-o-transform: translate3d(0, 0, 0);
    	transform: translate3d(0, 0, 0);
	}

	.slick-track {
		opacity: 1;
		width: 1354px;
		position: relative;
		top: 0;
		left: 0;
		display: block;

		.hero-slide {
			background-image: url("store/20190204969/assets/images/spotlights/HOME_desktop_450_1024.jpg");
			width: 1354px;
			position: relative;
			left: 0px;
			top: 0px;
			z-index: 999;
			opacity: 1;
			background-position: center;
			background-size: cover;
			overflow: hidden;
			display: flex;
		}
	}

	.slick-track::before, .slick-track::after {
		display: table;
		content: '';
	}

}
