/*
 * Flex UX Framework
 *
 * Filename: home-featured-banner-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .home .main-feature {
    background: url("../../../images/spotlights/banner-bg.jpg");
    background-size: cover;
    border-radius: 0;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
    height: 250px;
    max-width: 948px;

    @include mq('phone-wide', 'max') {
    	height: 200px;
    }

    @include mq('phone', 'max') {
    	height: 175px;
    }

    &--right {
    	background-image: url("../../../images/spotlights/banner-items.png");
    	flex: 1.5;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom center;

		@include mq('tablet-small', 'max') {
			background-image: url("../../../images/spotlights/banner-items-mob.png");
		}

		@include mq('tablet-small', 'max') {
			flex: 1;
		}
    }
    
	.product-info {
  		text-align: left;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		max-width: 350px;
		width: 100%;
		height: 100%;
		padding-left: 64px;

		  @include mq("tablet-small", "max") {
		    margin: auto auto 0;
		    padding-left: 45px;
		  }

		  @include mq("phone-wide", "max"){
		  	padding-left: 28px;
		  }

		  @include mq('phone', 'max') {
		  	padding-left: 18px;
		  }

		p {
			color: #726658;
			font-size: 20px;
			font-weight: 300;
			margin-bottom: 22px;

			@include mq('phone-wide', 'max'){
				font-size: calc(20px - .5vw);
			}

			@include mq('phone', 'max') {
				margin-bottom: 10px;
			}
		}

		.btn--green {
			@media only screen and (max-width: 375px) {
				width: 75%;
			}
		}
    }

 	> div {
		@include mq('tablet-small', 'max') {
			flex: 1;
		}
    }

    h1 {
		color: #00493D;
		font-family: $standard-font-face;
		font-size: 45px;
		line-height: 45px;
		font-weight: 300;

		@include mq("tablet", "max"){
			position: relative;
		    top: -8px;
		    // font-size: 48px !important;
		    line-height: 43px;
		}

		@include mq('tablet-small', 'max') {
			font-size: calc(32px + 2vw);
			line-height: 1em;
		}

		@include mq('phone-wide', 'max') {
			font-size: calc(26px + 2vw);
		}

    	@include mq("desktop", "min"){
		    position: relative;
		    top: -8px;
		    font-size: 45px;
		    line-height: 45px;
		}
	}
}

body.home h1{
	margin: 0;
}


.home .product-info--right {
  @include mq("tablet", "min"){
	    align-self: flex-end !important;
	    margin-right: 70px;
	}
}	